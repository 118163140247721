@tailwind base;
@tailwind components;
@tailwind utilities;
html {
    height: 100%;
}

body {
    margin: 0;
    font-family: 'Open Sans', sans-serif !important;
    /* font-family: 'Lato', sans-serif !important; */
    height: 100%;
    color: #212841 !important;
}

#root {
    height: 100%;
}

.container {
    max-width: 1140px;
    margin: 0 auto;
}

.hero-text {
    font-size: 55px;
}

video {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
}

.video--wrapper {
    position: relative;
}

.video--wrapper::before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, .4);
}

.hero3 .slick-dots{
    
    top: calc((100vh - 95px - 80px)/2);
    right: 10px;
    
    width: fit-content;
    height: 80px;
   
    z-index: 10;
    
    display: flex;
    align-items: center;
    flex-direction: column;
   

}
.hero3 .slick-dots li{
   
    
    border-radius: 50%;
    display: block;
    margin-block: 5px;
}

.hero3 .slick-dots li button::before{
    color: #EEF2F7;
    font-size: 1rem;
  }
  .hero3 .slick-dots li.slick-active button::before{
    color: #EEF2F7;
  }
    


.ant-collapse-expand-icon{
    display: flex;
    align-items: center;
    padding-top: 10px;
}


.hero3-text {
    height: calc(100vh - 95px);
}

.video1 {
    background: url(../videos/cropped_5.mp4);
}

@media only screen and (max-width: 1024px) {
    .container {
        max-width: 720px;
    }
    .hero-text {
        font-size: 70px;
    }
    
}

@media only screen and (max-width: 768px) {
    .container {
        max-width: 960px;
    }
    .hero-text {
        font-size: 60px;
    }
    
   
}

@media only screen and (max-width: 640px) {
    .container {
        max-width: 576px;
    }
    .hero-text {
        font-size: 40px;
    }
    
}

.pri-font {
    font-family: 'Oswald', sans-serif;
    /* font-family: 'Open Sans', sans-serif; */
    /* font-family: 'Lato', sans-serif; */
}

.hero {
    background: linear-gradient( 0deg, rgba(33, 40, 65, 0.7), rgba(33, 40, 65, 0.7)), url("../images/bg-3.jpg");
    background-size: cover;
    background-position: center;
}

.topbg {
    height: 100%;
}

@keyframes bounce {
    0% {
        margin-bottom: 0px;
    }
    10% {
        margin-bottom: 1px;
    }
    20% {
        margin-bottom: 2px;
    }
    30% {
        margin-bottom: 3px;
    }
    40% {
        margin-bottom: 4px;
    }
    50% {
        margin-bottom: 5px;
    }
    60% {
        margin-bottom: 6px;
    }
    70% {
        margin-bottom: 4px;
    }
    80% {
        margin-bottom: 3x;
    }
    90% {
        margin-bottom: 2px;
    }
    100% {
        margin-bottom: 1px;
    }
}


/* The element to apply the animation to */

.hero-down {
    margin-bottom: 0;
    animation-name: bounce;
    animation-duration: 900ms;
    animation-iteration-count: infinite;
}

.modal-wrap {
    position: fixed;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 999;
    overflow-x: hidden;
    background-color: rgba(33, 40, 65, 0.75);
    transition: opacity 250ms 700ms ease;
    padding: 32px 16px;
}

.scrollbar::-webkit-scrollbar-track {
    /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
    border-radius: 2px;
    background-color: #eee;
}

.scrollbar::-webkit-scrollbar {
    width: 8px;
}

.scrollbar::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #ccc;
}